import '../styles/footer.css'
import { Link } from 'react-router-dom'

const PageHead = ({ name, alt, image }) => {
    const currentYear = new Date().getFullYear();
    return (
        <section class="page-head" style={{backgroundImage: `url(${image})`}}>
            <div class="page-head-overlay">
                <div class="text-center">
                    <div className='page-title'>{(name == 'Sales' ? 'Book a Meeting with a Sales Representative' : name)}</div>
                    {alt != '' ? <div className='page-subtitle col-md-5'>{alt}</div> : ''}
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li class="breadcrumb-item active">{name}</li>
                    </ol>
                </div>
            </div>
        </section>
    )
    }

export default PageHead;