import '../styles/header.css'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { useMediaQuery } from 'react-responsive';

export default function Header() {

  const location = useLocation();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const isMobileView = useMediaQuery({ query: '(max-width: 768px)' })

  useEffect(() => {
    if (isMobileView) {
      isMenuOpen
        ? (document.body.style.overflow = 'hidden')
        : (document.body.style.overflow = 'auto');
    }
  }, [isMenuOpen, isMobileView]);

  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      // Set a scroll threshold where you want the header to become sticky
      const scrollThreshold = 100; // Adjust this value as needed

      if (window.scrollY > scrollThreshold) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const navigate = useNavigate();

  // Function to scroll to the #pricing section
  const scrollToPricing = () => {
    setIsMenuOpen(false)
    const pricingSection = document.getElementById('pricing');
    if (pricingSection) {
      pricingSection.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const scrollToDownload = () => {
    setIsMenuOpen(false)
    const pricingSection = document.getElementById('download');
    if (pricingSection) {
      pricingSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (isMobileView) {
      setIsMenuOpen(false)
    }
    const pagePath = window.location.pathname;
    const hashFragment = window.location.hash;
    const fullURL = pagePath + hashFragment;
    // Check if the current location is the home page
    if (fullURL === '/#pricing') {
      // If on the home page, scroll to #pricing
        setTimeout(scrollToPricing, 100); // Adjust the delay as needed
    }
    if (fullURL === '/#download') {
      // If on the home page, scroll to #pricing
        setTimeout(scrollToDownload, 100); // Adjust the delay as needed
    }
  }, [window.location.pathname, window.location.hash]);

  
  return (
    <>
    {/* <nav className={`pl-4 lg:pl-0 mx-auto ${ isMenuOpen ? 'toggled' : 'pr-3 lg:pr-0' } ${location.pathname == '/' && ''}`}>
    <div class="container flex flex-wrap items-center justify-between mx-auto md:py-8 pt-5 pb-8 px-4 2xl:px-10">
    <div className="flex items-center mr-4">
      <Link to="/" class="flex items-center space-x-4">
        <img src='/logo.png' className='h-14 lg:h-16'/>
      </Link>
    </div>
    <div class="flex md:hidden">
        <button data-collapse-toggle="navbar-cta" type="button" className="inline-flex justify-center items-center p-2 text-sm text-gray-500 rounded-lg md:hidden toggler" aria-controls="navbar-cta" aria-expanded={isMenuOpen} onClick={toggleMenu} >
            <span class="sr-only">Open main menu</span>
              <svg
                className="w-8 h-8"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <motion.path
                  fillRule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clipRule="evenodd"
                  animate={{ rotate: isMenuOpen ? 45 : 0, y: isMenuOpen ? 5 : 0 }}
                  initial={{ rotate: 0, y: 0 }}
                  exit={{ opacity: 0 }}
                  transition={{ type: 'spring', duration: 0.5 }}
                />
                <motion.path
                  fillRule="evenodd"
                  d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clipRule="evenodd"
                  animate={{ opacity: isMenuOpen ? 0 : 1 }}
                  initial={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ type: 'spring', duration: 0.5 }}
                />
                <motion.path
                  fillRule="evenodd"
                  d="M3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clipRule="evenodd"
                  animate={{ rotate: isMenuOpen ? -45 : 0, y: isMenuOpen ? -5 : 0 }}
                  initial={{ rotate: 0, y: 0 }}
                  exit={{ opacity: 0 }}
                  transition={{ type: 'spring', duration: 0.5 }}
                />
              </svg>
        </button>
    </div>
    <motion.div 
            initial={isMobileView ? { x: '100%' } : {}}
            animate={isMobileView ? { x: isMenuOpen ? '0%' : '100%' } : {}}
            exit={isMobileView ? { x: '100%' } : {}}
            transition={{ type: 'tween', duration: 0.3 }}
            className={`items-center justify-end lg:flex lg:flex-1 md:contents md:order-1 w-full ${
              isMobileView && !isMenuOpen ? 'hiddenx' : ''
            }`} id="navbar-cta">
        <div className='flex h-full flex-1'>

          <div class="flex flex-col items-center justify-end flex-1 p-4 md:p-0 mt-4 ml-0 lg:ml-10 font-medium rounded-lg md:flex-row space-x-8 lg:space-x-10 md:mt-0 md:border-0 nav-links">
              <div><Link to="/download">Download</Link></div>
              <div><Link to="/pricing">Pricing</Link></div>
              <div><Link to="/features">Features</Link></div>
              <div><Link to="/">Support</Link></div>
          </div>
          
          <div className='sm-toggler'>
            <div className=' w-full flex flex-col justify-between'>
              <div className='w-full p-3 text-white text-2xl flex flex-col space-y-5 text-right mt-16'>
                <div><Link to="/">Home</Link></div>
                <div><Link to="/download">Download</Link></div>
                <div><Link to="/pricing">Pricing</Link></div>
                <div><Link to="/features">Features</Link></div>
                <div><Link to="/">Support</Link></div>
              </div>
              <div>
                <div class="flex flex-col space-y-5 p-3 text-lg text-right">
                  <Link to="/privacy" class="text-neutral-500 hover:text-neutral-700">Privacy Policy</Link>
                  <Link to="/terms" class="text-neutral-500 hover:text-neutral-700">Terms and Conditions</Link>
                </div>
                <div className='flex flex-row justify-end space-x-3 px-3'>
                    <div><Link to="/signup" className="btn-main !rounded-md flex-1 !px-3">Log in</Link></div>
                    <div><Link to="/login" className="btn-main-alt !rounded-md flex-1 !px-3">Sign up for free</Link></div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </motion.div>
    </div>
    </nav> */}

    <nav class={`navbar navbar-expand-lg p-0 navbar-light ${isSticky ? 'sticky' : (location.pathname == '/' ? '' : 'sticky')}`}>
    <div class="container">
        <Link class="navbar-brand p-0" to="/">
            <img src="/logo.png"/>
        </Link>
        <button class="navbar-toggler menu-button" onClick={toggleMenu} type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
            <span class="fas fa-bars text-dark"></span>
        </button>
        <div class={`${isMenuOpen ? 'show' : 'collapse'} justify-content-end navbar-collapse`} id="navbarText">
            <div class="w-100 show-in-sm nav justify-content-end text-right pb-2 pt-3">
                <button class="navbar-toggler border-0" onClick={toggleMenu} type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="fas fa-times text-white fa-2x"></span>
                </button>
            </div>
            <ul class="navbar-nav ml-auto">
                <li class="nav-item">
                    <Link class="nav-link download" onClick={scrollToDownload} to="/#download">Download</Link>
                </li>
                <li class="nav-item">
                    <Link class="nav-link pricing" onClick={scrollToPricing} to="/#pricing">Pricing Plan</Link>
                </li>
                <li class="nav-item">
                    <Link class="nav-link features" to="/features">Features</Link>
                </li>
                <li class="nav-item">
                    <Link class="nav-link sales" to="/sales">Sales</Link>
                </li>
            </ul>
            <div class="form-inline">
                <Link class="btn header-btn" onClick={scrollToPricing} to="/#pricing">Get Started</Link>
            </div>
        </div>
    </div>
</nav>

    </>
  )
}
