import logo from './logo.svg';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import Features from './pages/Features';
import Sales from './pages/Sales';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import { AnimatePresence } from "framer-motion";
import '@fortawesome/fontawesome-free/css/all.min.css';


function App() {
  return (
    <AnimatePresence>
      <Router>
        <Routes>
          <Route path="/" index element={<Home/>} />
          <Route path="/#pricing" exact element={<Home/>} />
          <Route path="/features" element={<Features/>} />
          <Route path="/sales" element={<Sales/>} />
          <Route path="/terms" element={<Terms/>} />
          <Route path="/privacy" element={<Privacy/>} />
        </Routes>
      </Router>
    </AnimatePresence>
  );
}

export default App;
