import React, { useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';
import { useLocation } from 'react-router-dom';

const HomeLayout = ({ children }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  return (
    <div className={`mx-auto ${pathname !== '/' && 'pt-nav'}`}>
      <Header />
      {children}
      <Footer />
    </div>
  )
}

export default HomeLayout;