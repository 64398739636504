import React from 'react';
import {Link} from 'react-router-dom';
import HomeLayout from '../components/HomeLayout';
import { motion } from "framer-motion"
import PageHead from '../components/PageHead';
import "../styles/features.css"

export default function Features() {

    const features = [
        {
            name: 'Manage In One Place',
            desc: 'Morbilling pharetra sapiening ut mattis viverra. Curabitur magna.',
            icon: 'fas fa-sitemap',
        },
        {
            name: 'Quality Assurance',
            desc: 'Morbilling pharetra sapiening ut mattis viverra. Curabitur magna.',
            icon: 'fas fa-bed',
        },
        {
            name: 'Control & Track',
            desc: 'Morbilling pharetra sapiening ut mattis viverra. Curabitur magna.',
            icon: 'fas fa-chart-column',
        },
        {
            name: 'Visibility & Collaboration',
            desc: 'Morbilling pharetra sapiening ut mattis viverra. Curabitur magna.',
            icon: 'fas fa-cart-shopping',
        },
        {
            name: 'Control & Track',
            desc: 'Morbilling pharetra sapiening ut mattis viverra. Curabitur magna.',
            icon: 'fas fa-chart-column',
        },
        {
            name: 'Visibility & Collaboration',
            desc: 'Morbilling pharetra sapiening ut mattis viverra. Curabitur magna.',
            icon: 'fas fa-cart-shopping',
        }
    ]

    return (
    <HomeLayout>
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
        <main>
        <PageHead name="Features" alt="" image="https://images.unsplash.com/photo-1556155092-490a1ba16284?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80"/>
        <section className='features py-5'>
            <div class="mt-lg-5 container">
                <div class="row">
                    {features.map((feature) => (
                        <div key={feature.name} class="col-lg-4 col-md-6 col-sm-12">
                            <div class="single-feature">
                                <div class="icon">
                                    <i class={`${feature.icon}`}></i>
                                </div>
                                <h5 class="features-title">{feature.name}</h5>
                                <p>{feature.desc}</p>
                                <div class="button">
                                    <i class="fa fa-chevron-right"></i>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
        </main>
        </motion.div>
    </HomeLayout>
    )
}