import '../styles/footer.css'
import { Link } from 'react-router-dom'

export default function Footer() {
const currentYear = new Date().getFullYear();
  return (
    <footer class="page-footer">
        <div class="container">
            <div class="pt-5">
                <div class="row mt-3 text-black">
                    <div class="col-md-5 mb-4 footer-links">
                        <img class="mb-4" height="60" src="/logo.png"/>
                        <div class="text col-11">Morbi convallis bibendum urna ut viverra. Maecenas quis consequat libero, a feugiat eros. Nunc ut lacinia tortor morbi ultricies laoreet ullamcorper phasellus semper.</div>
                    </div>

                    <div class="col-md-2 mb-4 footer-links">
                        <h6>Helpful Links</h6>
                        <p>
                            <Link to="/terms"><i class="fas fa-angle-right"></i> Terms &amp; Conditions</Link>
                        </p>
                        <p>
                            <Link to="/privacy"><i class="fas fa-angle-right"></i> Privacy Policy</Link>
                        </p>
                        <p>
                            <Link to="/sales"><i class="fas fa-angle-right"></i> Sales</Link>
                        </p>
                    </div>
                    <div class="col-md-2 mb-4 footer-links">
                        <h6>Social Links</h6>
                        <p>
                            <a href="https://twitter.com" target={'_blank'}><i class="fas fa-angle-right"></i> Twitter</a>
                        </p>
                        <p>
                            <a href="https://linkedin.com" target={'_blank'}><i class="fas fa-angle-right"></i> Linked In</a>
                        </p>
                        <p>
                            <a href="https://facebook.com" target={'_blank'}><i class="fas fa-angle-right"></i> Facebook</a>
                        </p>
                    </div>
                    <div class="col-md-3 col-lg-3 mx-auto mb-4 footer-links">
                        <h6>Contact Us</h6>
                        <p>Perplexity Solved Solutions<br/>5851 Legacy Cir<br/>Plano, Tx 75024</p>
                        <p><a class="text-primary font-weight-600" href="support@simplytestlabs.com">support@simplytestlabs.com</a></p>
                        <div class="col-md-12 nav justify-content-start social-icons">
                        </div>
                    </div>
                </div>
            </div>
        <div class="">
        <div class="text-center text-secondary py-4 secondary-footer">
            <div class="text-center">
                <span>© {currentYear} Copyright - All Rights Reserved -</span>
                <a href="index" class="text-dark"> Simply Testlabs </a>
            </div>
        </div>
        </div>
        </div>
    </footer>
  )
}
