import React from 'react';
import '../styles/home.css'
import '../styles/pricing.css'
import { Link } from 'react-router-dom';
import HomeLayout from '../components/HomeLayout';
import { motion } from "framer-motion"


export default function Home() {

    const features = [
        {
            name: 'Manage In One Place',
            desc: 'Morbilling pharetra sapiening ut mattis viverra. Curabitur magna.',
            icon: 'fas fa-sitemap',
        },
        {
            name: 'Quality Assurance',
            desc: 'Morbilling pharetra sapiening ut mattis viverra. Curabitur magna.',
            icon: 'fas fa-bed',
        },
        {
            name: 'Control & Track',
            desc: 'Morbilling pharetra sapiening ut mattis viverra. Curabitur magna.',
            icon: 'fas fa-chart-column',
        },
        {
            name: 'Visibility & Collaboration',
            desc: 'Morbilling pharetra sapiening ut mattis viverra. Curabitur magna.',
            icon: 'fas fa-cart-shopping',
        }
    ]

  return (
    <HomeLayout>
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
        <main>
            <div class="main-banner">
            <div class="right-bg">
                <img src="/images/home-banner.png" class="img-fluid float-right" alt=""/>
            </div>
            <div class="banner-overlay">
                <img src="/images/banner-overlay.svg" class="img-fluid" alt=""/>
            </div>

            <div class="banner-text">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-5 col-md-12 col-sm-12">
                            <h1>Simple online testscript management system for teams</h1>
                            <p>Unlike traditional testcase management systems, that require days of training and are expensive, SimplyTestlabs takes only 10 minutes to set up.</p>
                            <div class="email-box">
                                <div class="input">
                                    <input type="email" placeholder="Enter your email"/>
                                    <button className="bg-main">Get started</button>
                                </div>
                                <span>30-day FREE trial - no credit card needed</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>

        <section class="features py-5" id="features">
            <div class="container mt-lg-5">
                <div class="text-center">
                    <h2 class="section-title">4 ways Simply Testlabs helps your IT &amp; Dev Teams succeed</h2>
                    <div class="col-lg-6 mx-auto">
                        <div class="section-subtitle">Keep your management and other departments aligned with the process of the testing teams</div>
                    </div>
                </div>
                <div class="row mt-5">
                    {features.map((feature) => (
                        <div key={feature.name} class="col-lg-3 col-md-6 col-sm-12">
                            <div class="single-feature">
                                <div class="icon">
                                    <i class={`${feature.icon}`}></i>
                                </div>
                                <h5 class="features-title">{feature.name}</h5>
                                <p>{feature.desc}</p>
                                <div class="button">
                                    <i class="fa fa-chevron-right"></i>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>

        
        <section className='pricing py-5' id='pricing'>
            <div class="container">
            <div class="text-center">
                    <h2 class="section-title">Pricing Plans</h2>
                    <div class="col-lg-6 mx-auto">
                        <div class="section-subtitle">Donec vulputate urna sed rutrum venenatis. Cras consequat magna quis arcu elementum, quis congue risus volutpat.</div>
                    </div>
                </div>
            <div class="row mt-5">
				<div class="col-lg-4 col-md-4 col-sm-12 mb-5">
					<div class="pricing-item">
						<div class="pricing-header">
							<h3 class="pricing-title">Small Team</h3>
						</div>
						<div class="pricing-body">
							<div class="price-wrapper">
								<span class="currency">$</span>
								<span class="price">89</span>
								<span class="period">/Month</span>
							</div>
							<ul class="list">
								<li class="active">5 Users Allowed</li>
								<li class="active">Create Unlimited Test Project</li>
								<li class="active">Create Unlimited Test Runs</li>
								<li class="active">Unlimited Team Commenting</li>
								<li class="active">Email Notification on Testing status</li>
								{/* <li>Data Security and Backups</li>
								<li>Unlimited Staff Accounts</li>
								<li>Web Booking Widget</li>
								<li>Monthly Reports and Analytics</li> */}
							</ul>
						</div>
						<div class="pricing-footer">
							<a href="#">Select Plan</a>
						</div>
					</div>
					
				</div>
				<div class="col-lg-4 col-md-4 col-sm-12 mb-5">
					<div class="pricing-item active">
						<div class="pricing-header">
							<h3 class="pricing-title">Mid-Size Teams</h3>
						</div>
						<div class="pricing-body">
							<div class="price-wrapper">
								<span class="currency">$</span>
								<span class="price">169</span>
								<span class="period">/Month</span>
							</div>
							<ul class="list">
								<li class="active">10 Users Allowed</li>
								<li class="active">Create Unlimited Test Project</li>
								<li class="active">Create Unlimited Test Runs</li>
								<li class="active">Unlimited Team Commenting</li>
								<li class="active">Email Notification on Testing status</li>
							</ul>
						</div>
						<div class="pricing-footer">
							<a href="#">Select Plan</a>
						</div>
					</div>
					
				</div>
				<div class="col-lg-4 col-md-4 col-sm-12 mb-5">
					<div class="pricing-item">
						<div class="pricing-header">
							<h3 class="pricing-title">Larger Teams</h3>
						</div>
						<div class="pricing-body">
							<div class="price-wrapper">
								<span class="currency">$</span>
								<span class="price">245</span>
								<span class="period">/Month</span>
							</div>
							<ul class="list">
								<li class="active">20 Users + $9 each additional user</li>
								<li class="active">Create Unlimited Test Project</li>
								<li class="active">Create Unlimited Test Runs</li>
								<li class="active">Unlimited Team Commenting</li>
								<li class="active">Email Notification on Testing status</li>
							</ul>
						</div>
						<div class="pricing-footer">
							<a href="#">Select Plan</a>
						</div>
					</div>
					
				</div>
			</div>
            </div>
        </section>

        <section class="download" id='download'>
            <div class="download-overlay py-5">
                <div class="text-center py-5">
                    <h2 class="section-title text-white">Download Simply Testlabs for Desktop</h2>
                    <h4 class="text-white mt-5">For Windows and Mac</h4>
                    <div class="section-subtitle font-85 mt-4 text-light">By downloading Simply Testlabs, you accept our <a><u>Privacy Policy</u></a> and <a><u>Terms and Conditions</u></a></div>
                    <div class="mt-5 col-lg-6 col-md-8 px-5 d-flex justify-content-between mx-auto download-btns">
                        <button class="btn btn-download"><i class="fab fa-windows"></i> Windows</button>
                        <button class="btn btn-download"><i class="fab fa-apple"></i> Mac</button>
                    </div>
                </div>
            </div>
        </section>

        </main>
        </motion.div>
    </HomeLayout>
  )
}
