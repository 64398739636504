import React from 'react';
import {Link} from 'react-router-dom';
import HomeLayout from '../components/HomeLayout';
import { motion } from "framer-motion"
import PageHead from '../components/PageHead';
import "../styles/sales.css"

export default function Sales() {

    return (
    <HomeLayout>
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
        <main>
        <PageHead name="Sales" alt="" image="https://images.unsplash.com/photo-1556155092-490a1ba16284?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80"/>
        <section className='sales py-5'>
            <div class="container">
                <div className='col-md-6'>
                    <h2 class="section-title">Get in touch</h2>
                    <p className='section-subtitle'>
                    Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Etiam tempus magna vel turpis pharetra dictum.
                    <br/><br/>
                    Sed blandit tempus purus, sed sodales leo rutrum vel. Nam vulputate ipsum ac est congue, eget commodo magna lobortis.
                    </p>
                    <button className='btn btn-main'>Book a meeting</button>
                </div>
                <div class="contact pt-5 mt-lg-5">
                    <div class="map-wrapper">
                        <div><iframe src="https://maps.google.com/maps?width=700&amp;height=440&amp;hl=en&amp;q=5851%20Legacy%20Circle+(Perplexity%20Solved%20Solutions)&amp;ie=UTF8&amp;t=&amp;z=13&amp;iwloc=B&amp;output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
                        </div>

                        <div class="contact-info">
                            <div class="item">
                                <i class="fa fa-location-arrow"></i>
                                <div class="txt">
                                    <span>Perplexity Solved Solutions<br/>5851 Legacy Cir<br/>Plano, Tx 75024</span>
                                </div>
                            </div>
                            <div class="item">
                                <i class="fa fa-envelope"></i>
                                <div class="txt">
                                    <span><a href="mailto:support@simplytestlabs.com">support@simplytestlabs.com</a></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </main>
        </motion.div>
    </HomeLayout>
    )
}